<template>
  <div class="page-settings">
    <Loader :state="load"/>
    Товары offline: {{countLocal}} тов.<br>
    Фото offline: {{countLocalPhoto}} тов.<br>
    <template v-if="!$store.state.offline">
      Товары на сервере: {{countRemote}} тов.<br><br>
      <button @click="sync">Синхронизировать</button>&nbsp;
      <button @click="cachePhoto">Кешировать фото</button>
      <button @click="clear">Очистить</button>
    </template>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import {ref} from "vue";

export default {
  components: {Loader},
  setup(props, {emit}) {
    emit('meta', {title: 'Настройки', back: '/'})

    let load = ref('Загрузка...')

    let countLocal = ref(0)
    const getCountLocal = async () => {
      load.value = 'Загрузка...'
      countLocal.value = await api.getGoodsCount('local')
      load.value = ''
    }
    getCountLocal()

    let countLocalPhoto = ref(0)
    if(navigator.serviceWorker)
      navigator.serviceWorker.controller.postMessage({type: 'GOODS-PHOTO-COUNT'})

    let countRemote = ref(0)
    const getRemoteCount = async () => {
      load.value = 'Загрузка...'
      countRemote.value = await api.getGoodsCount('remote')
      load.value = ''
    }
    getRemoteCount()

    const sync = async () => {
      load.value = 'Синхронизация...'
      await api.syncDB()
      await getCountLocal()
      load.value = ''
    }

    const clear = async () => {
      load.value = 'Удаление...'
      if(navigator.serviceWorker)
        navigator.serviceWorker.controller.postMessage({type: 'GOODS-PHOTO-CLEAR'})
      await api.clearGoodsLocal()
      await getCountLocal()
      if(navigator.serviceWorker)
        navigator.serviceWorker.controller.postMessage({type: 'GOODS-PHOTO-COUNT'})
      load.value = ''
    }

    const cachePhoto = async () => {
      load.value = 'Загрузка...'
      const limit = await api.getGoodsCount()
      const goods = await api.getGoods({limit})
      let urls = []
      goods.data.filter(g => !/svg/i.test(g.photo)).map((g,i) => {
        if(i > +countLocalPhoto.value - 10)
          urls.push(`./storage/goods/${g.id}/resize/cover.jpg`)
      })
      if(navigator.serviceWorker)
        navigator.serviceWorker.controller.postMessage({type: 'CACHE-GOODS-PHOTO', urls})
    }

    if(navigator.serviceWorker)
    navigator.serviceWorker.onmessage = (event) => {
      if(event.data.type === 'CACHE-GOODS-PHOTO-COUNT') countLocalPhoto.value = event.data.data
      if(event.data.type === 'CACHE-GOODS-PHOTO-PROCESS') load.value = event.data.data
      if(event.data === 'CACHE-GOODS-PHOTO-READY') {
        load.value = ''
        navigator.serviceWorker.controller.postMessage({type: 'GOODS-PHOTO-COUNT'})
      }
    };

    return {
      load, countRemote, countLocal, countLocalPhoto, sync, clear, cachePhoto
    }
  }
}
</script>

<style lang="less">
.page-settings {
  padding: 10px;
}
</style>
